import {FC, useEffect, useState} from 'react';
import {Navigate, Outlet, useNavigate} from 'react-router-dom';
import {Spin} from 'antd';


const AuthCheckRoute: FC = () => {
    const navigate = useNavigate();
    const [unAuthenticated, setUnAuthenticated] = useState(false);
    const [loading, setLoading] = useState(true);

    const adminRoles = [
        'admin',
        'super-admin',
        'support'
    ]

    useEffect(() => {
        const token = localStorage.getItem('token');
        token && navigateUserByRole()

        setLoading(false);
        setUnAuthenticated(true)
    }, [navigate]);

    const navigateUserByRole = () => {
        const user_type = localStorage.getItem('user_type');
        if (adminRoles.includes(user_type)) {
            navigate('/admin/dashboard')
        } else if (user_type === 'field-agent') {
            navigate('/field-agent/users')
        } else {
            navigate('/dashboard')
        }
    }

    if (loading) {
        return <Spin indicator={<div className='global_loader'></div>}
                     style={{height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center'}}/>;
    }

    return (
        <>
            {unAuthenticated ? <Outlet/> : <Navigate to="/"/>}
        </>
    );
};

export default AuthCheckRoute;
