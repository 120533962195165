import {CSSProperties, FC, useEffect, useState} from "react";
import {Badge, Button, Card, Col, Dropdown, Empty, Grid, Image, Menu, message, Modal, Row, Space} from 'antd';
import {Link, useLocation} from "react-router-dom";
import {BellOutlined} from '@ant-design/icons';
import {notificationupdate, usernotification} from "../../lib/API";
import Sidebar from "../Common/Sidebar/Sidebar";
import Logo from '../../Images/OTT 4 Me-white.png'
import './Header.css';

const {useBreakpoint} = Grid;

interface HeaderProps {
    title: string | null;
    mode?: string
}


const Header: FC<HeaderProps> = ({title, mode}) => {
    const screens = useBreakpoint();
    const location = useLocation();
    const {providerName} = location.state || {providerName: null};
    const showMenuIcon = location.pathname === "/dashboard" || '/admin/designer';
    const menuFirstClass = showMenuIcon ? "menu-first-default" : "menu-first-alternative";
    const [users, setUsers] = useState('');
    const [unreadNotifications, setUnreadNotifications] = useState(0);
    const [showWelcomeMessage, setShowWelcomeMessage] = useState<boolean>(true);
    const [notifications, setNotifications] = useState<any>({});
    const [selectedNotification, setSelectedNotification] = useState<any>(null);
    const [visible, setVisible] = useState(false);

    useEffect(() => {
        if (mode === 'design') {
            return
        }
        const user = localStorage.getItem('name');
        setUsers(user || 'null');
        fetchNotifications();
        setTimeout(() => {
            setShowWelcomeMessage(false)
        }, 5000);
    }, []);

    const fetchNotifications = () => {
        usernotification()
            .then((response) => {
                const Data = response.data.notification;
                setNotifications(Data);

                setUnreadNotifications(response.data.unread_notifications_total);
            })
            .catch((error) => {
                message.error('Failed to retrieve notifications. Please try again later.');
            });
    };

    const update = (notificationId: any) => {
        notificationupdate({id: notificationId, is_read: true})
            .then(() => {
                fetchNotifications();
            })
            .catch(() => {
                message.error("Notification not updated. Please try again later.");
            });
    };

    const notificationListStyle: CSSProperties = {
        maxHeight: '300px',
        width: '75vw',
        overflowY: 'auto',
    };

    const notificationList = (
        <>{mode === 'design' ?
            null
            :
            <Menu mode="vertical" style={notificationListStyle}>
                {Object.values(notifications).length > 0 ? (
                    Object.values(notifications).map((notification: any) => (
                        <Menu.Item key={notification.id}>
                            <div
                                onClick={() => {
                                    showNotificationContent(notification);
                                    update(notification.id);
                                }}
                                className={`notification-item ${notification.is_read ? 'read' : 'unread'}`}
                            >
                                <Row align="middle" gutter={[8, 8]}>
                                    <Col span={23} className="p-1">
                                        <div className="notification-title">

                                            {notification.notificationSubject ? (
                                                notification.notificationSubject
                                            ) : (
                                                <span className="fallback-text">No subject available</span>
                                            )}
                                        </div>
                                        <div className="notification-content">
                                            {
                                                notification.humanReadableTime ? (
                                                    notification.humanReadableTime
                                                ) : (
                                                    <span className="fallback-text">Long time ago</span>
                                                )
                                            }
                                        </div>
                                    </Col>
                                    <Col span={1} className="p-1">

                                        <div
                                            className={`notification-title-circle ${notification.is_read ? 'blink-green' : 'blink-red'}`}>
                                            {notification.is_read ? (
                                                <span style={{color: 'green', fontSize: '30px'}}
                                                      className="d-flex justify-content-end align-item-center ">&#8226;</span>

                                            ) : (
                                                <span style={{color: 'red', fontSize: '30px'}}
                                                      className="d-flex justify-content-end align-item-center ">&#8226;</span>
                                            )}
                                        </div>
                                    </Col>

                                </Row>

                            </div>
                        </Menu.Item>
                    ))
                ) : (
                    <Menu.Item>
                        <Empty description="No data Available" image={Empty.PRESENTED_IMAGE_SIMPLE}/>
                    </Menu.Item>
                )}
            </Menu>
        }
        </>
    );


    const showNotificationContent = (notification: any) => {
        setVisible(false);
        setSelectedNotification(notification);
    };

    return (
        <>
            <div className="header-wrapper">
                <Card className="header-card" bordered={false}>
                    <div className={`menufirst ${menuFirstClass}`}>
                        <Space size={120}>
                            {!showMenuIcon && (
                                <div className="menu-title">
                                    <span>{title}</span>
                                    {providerName && <span> {`${providerName} voucher`}</span>}
                                </div>
                            )}
                            {showMenuIcon && (
                                <Link to={mode === 'design' ? '#' : '/dashboard'}>
                                    <Image
                                        src={Logo}
                                        preview={false}
                                    />
                                </Link>
                            )}
                        </Space>
                        <Space size={120} style={{justifyContent: 'flex-end'}}>
                            <Row gutter={[16, 16]}>
                                <Col span={12}>
                                    <Dropdown overlay={notificationList} trigger={["click"]}>
                                        <Badge className="me-3" count={unreadNotifications}>
                                            <Button
                                                shape="circle"
                                                className="header-btn"
                                                icon={<BellOutlined/>}
                                                type="text"
                                            />
                                        </Badge>
                                    </Dropdown>
                                </Col>
                                {screens.lg || screens.xl || screens.xxl || screens.md
                                    ? null : (
                                        <Col span={12}>
                                            <Sidebar/>
                                        </Col>
                                    )}
                            </Row>
                        </Space>
                    </div>
                    {(showMenuIcon && showWelcomeMessage) && (
                        location.pathname === '/dashboard' &&
                        <div className={'username hidden'}>
                            <span>HELLO, {users}</span>
                        </div>
                    )}
                </Card>
            </div>
            {selectedNotification && (
                <Modal
                    title={selectedNotification.notificationSubject}
                    open={true}
                    closable={false}
                    footer={[
                        <Button
                            key="ok"
                            className="notification-modal-btn text-dark fw-4"
                            onClick={() => setSelectedNotification(null)}
                        >
                            OK
                        </Button>,
                    ]}
                >
                    <div>
                        {
                            selectedNotification.inAppMessage == null ? (
                                <>
                                    <div className=""
                                         dangerouslySetInnerHTML={{__html: selectedNotification.inAppMessage}}/>
                                </>
                            ) : (
                                <>
                                    <div className=""
                                         dangerouslySetInnerHTML={{__html: selectedNotification.inAppMessage}}/>
                                </>
                            )
                        }
                    </div>
                </Modal>
            )}
        </>
    );
}

export default Header;