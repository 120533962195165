import {FC, ReactNode, useEffect, useState} from 'react';
import {Navigate, useNavigate} from 'react-router-dom';
import {privateroute} from '../lib/API';
import {Spin} from 'antd';

interface LayoutProps {
    children: ReactNode;
}

const PrivateRoute: FC<LayoutProps> = ({children}) => {
    const navigate = useNavigate();
    const [authenticated, setAuthenticated] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            privateroute()
                .then((response) => {
                    const user_type = localStorage.getItem('user_type');
                    if (user_type === 'customer') {
                        setAuthenticated(true);
                    }
                    setLoading(false);
                })
                .catch((error) => {
                    setAuthenticated(false);
                    setLoading(false);
                    localStorage.clear();
                    navigate('/');
                });
        };

        fetchData();
    }, [navigate]);

    if (loading) {
        return <Spin indicator={<div className='global_loader'></div>}
                     style={{height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center'}}/>;
    }

    return (
        <>
            {authenticated ? children : <Navigate to="/"/>}
        </>
    );
};

export default PrivateRoute;
