import "./Navigation.css";
import {Image, Menu} from "antd";
import Logo from '../../../Images/OTT 4 Me-white.png'
import {adminlogout} from "../../../lib/API";
import {AdminItems} from "../../../lib/MenuItems";
import {CSSProperties, FC, useEffect, useState} from "react";
import {Link, useLocation, useNavigate} from "react-router-dom";

const LogoStyle: CSSProperties = {
  display: "block",
  height: "100px",
  width: "260px",
  position: "fixed",
  background: "#210a30",
  zIndex: "1",
};

const Sidebar: FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const url = location.pathname;
  const [activeMenuItem, setActiveMenuItem] = useState(url);
  const perms = localStorage.getItem("perms");
  
  useEffect(() => {
    setActiveMenuItem(url);
  }, [url]);

  const handleMenuItemClick = (key: string) => {
    setActiveMenuItem(key);
  };

  const handleSignOut = async () => {
    const token = localStorage.getItem("token");
    if (token) {
      adminlogout(token)
        .then((response) => {
          localStorage.clear();
          navigate("/admin/login");
        })
        .catch((error) => {
          navigate("/admin/login");
        });
    }
  };

  return (
    <>
      <div style={LogoStyle}>
        <Image
          src={Logo}
          preview={false}
        />
      </div>
      <Menu
        mode="inline"
        selectedKeys={[activeMenuItem]}
        style={{
          backgroundColor: "#210a30",
          marginTop: "120px",
          marginBottom: "20px",
        }}
      >
        {AdminItems.map((group) => {
          return (
            <Menu.ItemGroup key={group.key}>
              {group?.groupItems?.map((groupItem) => {
                return (
                  <>
                    {
                      groupItem.subs.length <= 0 ? (
                        <>
                          {(perms.includes(groupItem?.key) || groupItem?.key === '11' || groupItem?.key === '10') && 
                            <Menu.Item
                              key={groupItem?.key}
                              icon={groupItem?.icon && <groupItem.icon />}
                              onClick={groupItem?.key === '10' ? (() => handleMenuItemClick("10")) : (groupItem?.key === '11' ? handleSignOut : () => {})}
                              className={`${
                                activeMenuItem === groupItem?.link
                                  ? "sidebar-menu-active"
                                  : "sidebar-menu"
                              }`}
                            >
                              <Link to={groupItem?.link} style={{ textDecoration: "none" }}>
                                {groupItem?.label}
                              </Link>
                            </Menu.Item>
                          }
                        </>) :
                        <>
                          {perms?.includes(groupItem?.key) && 
                            <Menu.SubMenu
                              key={groupItem?.key}
                              icon={groupItem?.icon && <groupItem.icon />}
                              title={groupItem?.label}
                              className={`${
                                activeMenuItem.includes(groupItem?.key)
                                  ? "sidebar-submenu-active"
                                  : "sidebar-menu"
                              }`}
                            >
                              {groupItem?.subs?.map((sub) => {
                                return (
                                  <>
                                    {perms?.includes(sub?.key) &&
                                      <Menu.Item
                                        key={sub?.key}
                                        className={`${
                                          activeMenuItem === sub?.link
                                            ? "sidebar-submenu-active"
                                            : "sidebar-menu"
                                        }`}
                                      >
                                        <Link
                                          to={sub?.link}
                                          style={{ textDecoration: "none" }}
                                        >
                                          {sub?.label}
                                        </Link>
                                      </Menu.Item>
                                    }
                                  </>
                                )
                              })}
                            </Menu.SubMenu>
                          }
                        </>
                    }
                  </>
                )
              })}
            </Menu.ItemGroup>
          )
        })}
      </Menu>
    </>
  );
};

export default Sidebar;
