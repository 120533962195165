export const theme = {
    token: {
        colorPrimary: '#8A2BE2',
        borderRadius: 5,
        padding:20,
    },
    components: {
        Button: {
            colorBorder: '#46FF6D',
            fontFamily: 'Space Grotesk, sans-serif',
            fontWeight: 600,
        },
        Typography: {
            colorLink: '#000',
        }
    }
}