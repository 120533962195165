import './Footer.css';
import {Layout, Menu} from 'antd';
import {FooterItems} from '../../lib/MenuItems';
import {Link, useLocation} from 'react-router-dom';
import {FC} from "react";

const {Footer} = Layout;

interface Props {
    mode?: string
}

const CustomFooter: FC<Props> = ({mode}) => {
    const location = useLocation();

    return (
        <>
            <Footer
                style={{
                    position: mode === 'design' ? 'sticky' : 'fixed',
                    width: mode === 'design' ? '100%' : '100vw',
                }}
                className="footer"
            >
                <Menu
                    className='menu'
                    mode="horizontal"
                    disabledOverflow
                    selectedKeys={[FooterItems.filter((item: any) => item.link === `${location.pathname}`)[0]?.key]}
                >
                    {FooterItems.map((item) => (
                        <Menu.Item
                            key={item.key}
                            icon={<item.icon/>}
                            style={{
                                width: '20%',
                                lineHeight: '30px'
                            }}
                            className='menu-item'
                        >
                            <Link
                                to={mode === 'design' ? '#' : item.link}
                                className='link-item'
                                style={{display: 'flex', flexDirection: 'column', width: '100%'}}
                            >
                                <p
                                    style={{
                                        position: 'relative',
                                        fontSize: '.85rem'
                                    }}
                                >
                                    {item.label}
                                </p>
                            </Link>
                        </Menu.Item>
                    ))}
                </Menu>
            </Footer>
        </>
    );
};

export default CustomFooter;
