import { Image } from "antd"
import StackedImage from '../../Images/Login screen-banner.png'

const RestrictPageWrapper = () => {
    return (
        <div className='restrict-page-wrapper'>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', height: '100%'}}>
                <Image
                    src={StackedImage}
                    alt='ott_logo'
                    preview={false}
                    style={{ marginBottom: '2rem' }}
                />
                This site can only  be viewed on mobile devices. Please visit from a mobile device.
            </div>
        </div>
    )
}

export default RestrictPageWrapper