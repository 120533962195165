import {Navigate, RouteObject, useLocation, useNavigate} from 'react-router-dom';
import {FieldAgentUsers} from './components'
import {FC, ReactNode, useEffect, useState} from 'react';
import {privateroute} from '../lib/API';
import {Spin} from 'antd';
import FieldAgentLayout from '../Components/Common/FieldAgentLayout';

interface LayoutProps {
    children: ReactNode;
}

const PrivateRoute: FC<LayoutProps> = ({children}) => {
    const navigate = useNavigate();
    const location = useLocation();
    const url = location.pathname.split('/').at(2);
    const [authenticated, setAuthenticated] = useState<boolean>(false);
    const [authorized, setAuthorized] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(true);

    const allowedRoles = [
        'field-agent',
    ]

    useEffect(() => {
        const fetchData = async () => {
            privateroute()
                .then((response) => {
                    localStorage.setItem('perms', response?.permissions);
                    if (allowedRoles.includes(response?.user_type)) {
                        setAuthenticated(true);

                        if (response?.permissions?.includes(url) || url === 'unauthorized') {
                            setAuthorized(true);
                        }
                    }

                    setLoading(false);
                })
                .catch((error) => {
                    setAuthenticated(false);
                    setAuthorized(false);
                    setLoading(false);
                    localStorage.clear();
                    navigate('/field-agent/login');
                });
        };

        fetchData();
    }, [navigate]);

    if (loading) {
        return <Spin indicator={<div className='global_loader'></div>}
                     style={{height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center'}}/>;
    }

    return (
        <>
            {authenticated ? (authorized ? children : <Navigate to="/field-agent/unauthorized"/>) :
                <Navigate to="/field-agent/login"/>}
        </>
    );
};

const fieldAgentRoutes: RouteObject = {
    path: '/field-agent',
    element:
        <PrivateRoute>
            <FieldAgentLayout/>
        </PrivateRoute>,
    children:
        [
            {
                path: "*",
                element: <Navigate to={'/field-agent/users'}/>,
            },
            {
                path: 'users',
                element: <FieldAgentUsers/>,
            },
        ]
}

export {fieldAgentRoutes};