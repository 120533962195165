import {FC, useEffect} from "react";
import {useRoutes} from "react-router-dom";
import "./App.css";
import {ConfigProvider} from "antd";
import routes from "./Routes";
import TagManager from 'react-gtm-module';
import {theme} from "./lib/theme";

const App: FC = () => {
    const router = useRoutes(routes)
    const gtm_id = process.env.REACT_APP_GTM_ID

    useEffect(() => {
      if (gtm_id) {
        const tagManagerArgs = {
          gtmId: gtm_id
        };
        TagManager.initialize(tagManagerArgs);
      }
    }, [gtm_id]);

  return (
    <ConfigProvider theme={theme}>
      {router}
    </ConfigProvider>
  );
};

export default App;