import {FC, useState} from 'react';
import {Button, Drawer, Flex, Space, Typography} from 'antd';
import {
  CloseCircleOutlined,
  FacebookOutlined,
  InstagramOutlined,
  LogoutOutlined,
  RightCircleOutlined,
  TikTokOutlined,
  WhatsAppOutlined,
  XOutlined
} from '@ant-design/icons';
import logo from '../../../Images/OTT 4 Me-white.png';
import {Link, useNavigate} from 'react-router-dom';
import {userlogout} from '../../../lib/API';

const CustomMenuIcon: FC = () => (
    <svg
        width="20"
        height="16"
        viewBox="0 0 20 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <rect x="4" width="16" height="2" rx="1" fill="white"/>
        <rect y="6.85718" width="20" height="2" rx="1" fill="white"/>
        <rect x="8" y="14" width="12" height="2" rx="1" fill="white"/>
    </svg>
);

const {Title, Text} = Typography;

const Sidebar: FC = () => {
    const navigate = useNavigate();
    const [visible, setVisible] = useState<boolean>(false);

    const showDrawer = () => {
        setVisible(true);
    };

    const handleLogout = async () => {
        const token = localStorage.getItem('token');
        if (token) {
            userlogout(token)
                .then((response) => {
                    localStorage.clear();
                    navigate('/');
                })
                .catch((error) => {
                    navigate('/');
                });
        }
    }

    const onClose = () => {
        setVisible(false);
    };

    const drawerHeader = (
        <Flex className='drawer-header'>
            <img
                src={logo}
                alt="Logo"
                className='header-logo'
            />
        </Flex>
    );

    return (
        <div>
            <div onClick={showDrawer}>
                <CustomMenuIcon/>
            </div>
            <Drawer
                placement="right"
                closable={true}
                onClose={onClose}
                open={visible}
                styles={{header: {backgroundColor: '#111111', color: '#fff'}}}
                title={drawerHeader}
                closeIcon={<CloseCircleOutlined className='header-icon'/>}
            >
                <Space size={5} direction='vertical' style={{padding: '1rem'}}>
                    <Link to='/user/profile' onClick={() => setVisible(false)}>
                        <Space align='baseline'>
                            <Title level={4}>Profile</Title>
                            <RightCircleOutlined style={{color: '#000'}}/>
                        </Space>
                    </Link>

                    <Space align='baseline'>
                        <Title level={4}>FAQs</Title>
                        <RightCircleOutlined/>
                    </Space>

                    <Space direction='vertical'>
                        <Title level={5}>Help</Title>
                        <Button type='text' target='_blank' href='https://api.whatsapp.com/send?phone=27843255632'
                                icon={<WhatsAppOutlined/>}>+27 84 325 5632</Button>
                        <Text style={{marginLeft: '1rem'}}>Email: support@ott-mobile.com</Text>
                        <Text style={{marginLeft: '1rem'}}>Call: +27 87 805 0688</Text>
                    </Space>

                    <Space size={5} direction='vertical'>
                        <Title level={4} style={{marginTop: '2rem'}}>Connect</Title>
                        <Text>#MoneyMyWay</Text>
                        <Space>
                            <FacebookOutlined style={{fontSize: '1.5rem'}}/>
                            <InstagramOutlined style={{fontSize: '1.5rem'}}/>
                            <XOutlined style={{fontSize: '1.5rem'}}/>
                            <TikTokOutlined style={{fontSize: '1.5rem'}}/>
                        </Space>
                    </Space>

                    <Space align='baseline'>
                        <Title level={4}>Terms of Use</Title>
                        <RightCircleOutlined/>
                    </Space>

                    <Button onClick={handleLogout} icon={<LogoutOutlined/>}>Logout</Button>
                </Space>
            </Drawer>
        </div>
    );
};
export default Sidebar;
