import {Navigate, RouteObject, useLocation, useNavigate} from 'react-router-dom';
import Master from '../Admin/Common/Master';
import {
    Admin,
    Collect,
    CompEntries,
    CompetitionDetail,
    CompetitionForm,
    Competitions,
    Coupons,
    CreateNotification,
    Customers,
    CustomProviders,
    Dashboard,
    EditNotification,
    Exports,
    HealthChecks,
    Imports,
    LuckyDraw,
    MediaLibrary,
    Notification,
    NotificationSettings,
    OTTSponsors,
    PageDesigner,
    Payout,
    Providers,
    Roles,
    ServiceProviders,
    SurveyDashboard,
    SurveyForm,
    Surveys,
    Transactions,
    UnAuthorized,
    User,
    Voucher,
    Vouchers
} from './components'
import {FC, ReactNode, useEffect, useState} from 'react';
import {privateroute} from '../lib/API';
import {Spin} from 'antd';

interface LayoutProps {
    children: ReactNode;
}

const PrivateRoute: FC<LayoutProps> = ({children}) => {
    const navigate = useNavigate();
    const location = useLocation();
    const url = location.pathname.split('/').at(2);
    const [authenticated, setAuthenticated] = useState<boolean>(false);
    const [authorized, setAuthorized] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(true);

    const allowedRoles = [
        'admin',
        'super-admin'
    ]

    useEffect(() => {
        const fetchData = async () => {
            privateroute()
                .then((response) => {
                    localStorage.setItem('perms', response?.permissions);
                    if (allowedRoles.includes(response?.user_type)) {
                        setAuthenticated(true);

                        if (response?.permissions?.includes(url) || url === 'unauthorized') {
                            setAuthorized(true);
                        }
                    }

                    setLoading(false);
                })
                .catch((error) => {
                    setAuthenticated(false);
                    setAuthorized(false);
                    setLoading(false);
                    localStorage.clear();
                    navigate('/admin/login');
                });
        };

        fetchData();
    }, [navigate]);

    if (loading) {
        return <Spin indicator={<div className='global_loader'></div>}
                     style={{height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center'}}/>;
    }

    return (
        <>
            {authenticated ? (authorized ? children : <Navigate to="/admin/unauthorized"/>) :
                <Navigate to="/admin/login"/>}
        </>
    );
};

const adminRoutes: RouteObject = {
    path: '/admin',
    element:
        <PrivateRoute>
            <Master/>
        </PrivateRoute>,
    children:
        [
            {
                path: "*",
                element: <Navigate to={'/admin/dashboard'}/>,
            },
            {
                path: "unauthorized",
                element: <UnAuthorized/>,
            },
            {
                path: "dashboard",
                element: <Dashboard/>,
            },
            {
                path: "users/admins",
                element: <Admin/>,
            },
            {
                path: "voucher",
                element: <Voucher/>
            },
            {
                path: "vouchers",
                element: <Vouchers/>
            },
            {
                path: "coupons",
                element: <Coupons/>
            },
            {
                path: "second_chance/competitions",
                element: <Competitions/>
            },
            {
                path: "second_chance/competitions/lucky-draw",
                element: <LuckyDraw/>
            },
            {
                path: "second_chance/competitions/entries/:id",
                element: <CompEntries/>
            },
            {
                path: "second_chance/competitions/form",
                element: <CompetitionForm/>
            },
            {
                path: "transactions",
                element: <Transactions/>
            },
            {
                path: "collect",
                element: <Collect/>
            },
            {
                path: "payout/providers",
                element: <Providers/>
            },
            {
                path: "custom/providers",
                element: <CustomProviders/>
            },
            {
                path: "imports/voucher",
                element: <Imports/>
            },
            {
                path: "payout/:submenu",
                element: <Payout/>
            },
            {
                path: "second_chance/sponsors",
                element: <OTTSponsors/>
            },
            {
                path: "second_chance/surveys",
                element: <Surveys/>
            },
            {
                path: "second_chance/service_providers",
                element: <ServiceProviders/>
            },
            {
                path: "second_chance/issuers",
                element: <Customers/>
            },
            {
                path: "survey-stats/:id",
                element: <SurveyDashboard/>
            },
            {
                path: "survey-form",
                element: <SurveyForm/>
            },
            {
                path: "users/user",
                element: <User/>
            },
            {
                path: "roles",
                element: <Roles/>
            },
            {
                path: "notifications_view",
                element: <Notification/>
            },
            {
                path: "notifications/edit/:id",
                element: <EditNotification/>
            },
            {
                path: "notifications_create",
                element: <CreateNotification/>
            },
            {
                path: 'secondchance/competition/:competitionID',
                element: <CompetitionDetail/>
            },
            {
                path: "settings/designer",
                element: <PageDesigner/>
            },
            {
                path: "settings/library",
                element: <MediaLibrary/>
            },
            {
                path: "settings/health",
                element: <HealthChecks/>
            },
            {
                path: "settings/exports",
                element: <Exports/>
            },
            {
                path: "settings_main",
                element: <NotificationSettings/>
            }
        ]
}

export {adminRoutes};
