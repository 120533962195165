import {Layout} from 'antd'
import {Outlet} from 'react-router-dom';
import AdminHeader from '../../Admin/Common/Header/AdminHeader';
import Sidebar from '../../FieldAgent/Sidebar/Navigation';

const { Content, Sider, Header, Footer } = Layout;

const FieldAgentLayout = () => {
    return (
        <Layout hasSider>
            <Sider width={280} className="admin-sider">
                <Sidebar />
            </Sider>
            <Layout className='admin-content-layout'>
                <Header>
                    <AdminHeader title={'OTT 4 Me Field Agent'} />
                </Header>
                <Content className='admin-content'>
                    <Outlet />
                </Content>
                <Footer style={{ textAlign: 'center' }}>
                    © OTT Mobile Technologies 2023
                </Footer>
            </Layout>
        </Layout>
    )
}

export default FieldAgentLayout