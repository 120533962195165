import {RouteObject} from 'react-router-dom';
import FrontMaster from '../Components/Common/FrontMaster';
import {
  BuyVoucherSteps,
  CheckTransactionStatus,
  ClientCoupons,
  ClientProviders,
  EnterCompetition,
  LandingPage,
  LeaderBoard,
  Leagues,
  LottoDrawResults,
  LottoResults,
  OttBuyVourcherSteps,
  Settings,
  Survey,
  TransactionHistory,
  UserProfile,
  VoucherDetails,
  Win,
  Winning
} from './components'
import PrivateRoute from './PrivateRoute';

const frontRoutes: RouteObject = {
    path: '/',
    element:
        <PrivateRoute>
            <FrontMaster/>
        </PrivateRoute>,
    children:
        [
            {
                path: 'dashboard',
                element: <LandingPage/>,
            },
            {
                path: 'win',
                element: <Win/>,
            },
            {
                path: 'gifts',
                element: <ClientCoupons/>,
            },
            {
                path: 'enter-competition/:id',
                element: <EnterCompetition/>,
            },
            {
                path: 'enter-survey/:id',
                element: <Survey/>,
            },
            {
                path: 'voucher-details/:id',
                element: <VoucherDetails/>,
            },
            {
                path: 'enter-competition/:id/winning',
                element: <Winning/>,
            },
            {
                path: 'transaction-history',
                element: <TransactionHistory/>,
            },
            {
                path: 'competition-winners',
                element: <LeaderBoard/>,
            },
            {
                path: 'settings',
                element: <Settings/>,
            },
            {
                path: 'providers',
                element: <ClientProviders/>,
            },
            {
                path: 'buy-payout-voucher/:providerID',
                element: <BuyVoucherSteps/>,
            },
            {
                path: 'buy-ott-voucher',
                element: <OttBuyVourcherSteps/>,
            },
            {
                path: 'check-transaction',
                element: <CheckTransactionStatus/>,
            },
            {
                path: 'user/profile',
                element: <UserProfile/>,
            },
            {
                path: 'leagues',
                element: <Leagues/>,
            },
            {
                path: 'lotto',
                element: <LottoResults/>,
            },
            {
                path: 'lotto/:id',
                element: <LottoDrawResults/>,
            },
        ]
}

export {frontRoutes};