import {FC, useEffect, useState} from 'react';
import {Avatar, Flex, Typography} from 'antd';
import {UserOutlined} from '@ant-design/icons';
import "./Header.css";

interface HeaderProps {
    title: string | null;
}

const { Title } = Typography;

const AdminHeader: FC<HeaderProps> = ({title}) => {
    const [username, setUsername] = useState<string>('');

    useEffect(() => {
        const storedUser = localStorage.getItem('name');
        setUsername(storedUser || 'Username');
    }, []);

    return (
        <div className="header">
            <Title level={3} className='header-left'>{title}</Title>
            <Flex align='baseline' gap={5}>
                <Avatar
                    size={27}
                    icon={<UserOutlined/>}
                    className="avatar"
                />
                <span className='user'>
                    <Title level={4}>
                        {`Hello, ${username || 'Username'}`}
                    </Title>
                </span>
            </Flex>
        </div>

    );
};

export default AdminHeader;
