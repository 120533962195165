import Sidebar from "./Sidebar/Navigation";
import {Outlet, useLocation} from "react-router-dom";
import { Layout } from "antd";
import { FC } from "react";
import { title } from "../../lib/Constants";
import AdminHeader from "./Header/AdminHeader";

const { Content, Footer, Sider, Header } = Layout;

const Master: FC = () => {
  const location = useLocation();
  const url = location.pathname;
  const selectedTitle = title.find((title) => url.startsWith(title.id))
  const titleName = selectedTitle ? selectedTitle.name : "OTT 4 Me Dashboard";

  return (
    <Layout hasSider>
      <Sider width={280} className="admin-sider">
        <Sidebar />
      </Sider>
      <Layout className="admin-content-layout">
        <Header>
          <AdminHeader title={titleName} />
        </Header>
        <Content className="admin-content">
          <Outlet />
        </Content>
        <Footer style={{ textAlign: 'center' }}>
          © OTT Mobile Technologies 2023
        </Footer>
      </Layout>
    </Layout>
  );
};

export default Master;
